import React from 'react';
import { Typography } from "../components/shared/Typography";


const Home = () => {

  return (
    <div style={{ padding: "16px 24px", color: "#44596e" }}>
      <div style={{ marginBottom: "24px" }}>
        <Typography variant="h4" fontWeight={600}>
          GAK's Tech Flow(<a href="https://www.linkedin.com/in/gautam-ajey-khanapuri-1724071ab/">@Linkedin</a>, <a href="mailto:khanapuri.g@northeastern.edu">@NEU</a>, <a href="mailto:gautam.khanapuri@gmail.com">@gmail</a>)
        </Typography>
        <br/>
        <Typography variant="h4" fontWeight={400}>
          NEU Impact Symposium February 26th, 2025(<a href="https://forms.office.com/Pages/ResponsePage.aspx?id=gcLuqKOqrk2sm5o5i5IV50kCWK9AwCdKtmYrCs_GuTZUQVE4TFdIUjRSR0FaMllZUjhQRDZPVk1VMy4u">Inputs/Feeback Form</a>)
        </Typography>

      </div>
      <div>
        <Typography variant="h5">
          Nose2 update demonstration video (YouTube).
        </Typography>
      </div>
      <div>
        <Typography variant="body">
          Nose2 Test Runner Updated for fast-fail to fail required number of failures.
        </Typography>
        <br/>
      </div>
      <div>
        <Typography variant="h5">
          Python Slicing: An interesting feature.
        </Typography>
      </div>
      <div>
        <Typography variant="body">
          Python Slicing: An interesting feature. A string can be reversed with [-1::-1], it works like an extended slice [::-1].
        </Typography>
        <br/>
      </div>
    </div>

  );
};
export default Home;
